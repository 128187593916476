export default [
  {
    path: '/sales-settings',
    meta: {label: 'Sales Settings'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'sales-settings',
      meta: {
        permission: 'show_all_sales_settings'
      },
      component: () =>
        import('./../../views/salessettings/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Create Sales Settings',
          permission: 'create_sales_setting'
        },
        name: 'CreateSalesSettings',
        component: () =>
          import('./../../views/salessettings/create.vue'),
      },
      {
        path: ':id/edit',
        meta: {
          label: 'Edit Sales Settings',
          permission: 'edit_sales_setting'
        },
        name: 'EditSalesSettings',
        component: () =>
          import('./../../views/salessettings/edit.vue')
      },
      {
        path: ':id',
        meta: {
          label: 'Show Sales Settings',
          permission: 'show_single_sales_setting'
        },
        name: 'ShowSalesSettings',
        component: () =>
          import('./../../views/salessettings/show.vue')
      },
    ]
  },

  {
    path: '/sales-mappings',
    meta: {label: 'Sales Mapping'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'sales-mapping',
      meta: {
        permission: 'show_all_sales_mappings'
      },
      component: () =>
        import('./../../views/salesmappings/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Sales Mapping Create',
          permission: 'create_sales_mapping'
        },
        name: 'CreateSalesMapping',
        component: () =>
          import('./../../views/salesmappings/create.vue'),
      },
      {
        path: ':id',
        meta: {
          label: 'Sales Mapping Details',
          permission: 'show_single_sales_mapping'
        },
        name: 'SalesMapping',
        component: () =>
          import('./../../views/salesmappings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: {
          label: 'Edit Sales Mapping',
          permission: 'edit_sales_mapping'
        },
        name: 'EditSalesMapping',
        component: () =>
          import('./../../views/salesmappings/edit.vue')
      },
    ]
  },
  {
    path: '/branch-mappings',
    meta: {label: 'Branch Mapping'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'branch-mapping',
      meta: {
        permission: 'show_all_branch_mappings'
      },
      component: () =>
        import('./../../views/branchmappings/index.vue'),
    },
      // {
      //   path: 'create',
      //   meta: {
      //     label: 'Branch Mapping Create',
      //     permission: 'create_branch_mapping'
      //   },
      //   name: 'CreateBranchMapping',
      //   component: () =>
      //     import('./../../views/branchmappings/create.vue'),
      // },
      // {
      //   path: ':id',
      //   meta: {
      //     label: 'Branch Mapping Details',
      //     permission: 'show_single_branch_mapping'
      //   },
      //   name: 'BranchMapping',
      //   component: () =>
      //     import('./../../views/branchmappings/show.vue'),
      // },
      // {
      //   path: ':id/edit',
      //   meta: {
      //     label: 'Edit Branch Mapping',
      //     permission: 'edit_branch_mapping'
      //   },
      //   name: 'EditBranchMapping',
      //   component: () =>
      //     import('./../../views/branchmappings/edit.vue')
      // },
    ]
  },

  {
    path: '/branch-sales',
    meta: {label: 'Branch Sales'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'branch-sales',
      meta: {
        permission: 'show_all_branch_sales'
      },
      component: () =>
        import('./../../views/branchsales/index.vue'),
    },
      // {
      //   path: 'create',
      //   meta: {
      //     label: 'Branch Sales Create',
      //     permission: 'create_branch_sales'
      //   },
      //   name: 'CreateBranchSales',
      //   component: () =>
      //     import('./../../views/branchsales/create.vue'),
      // },
      // {
      //   path: ':id',
      //   meta: {
      //     label: 'Branch Sales Details',
      //     permission: 'show_single_branch_sales'
      //   },
      //   name: 'BranchSales',
      //   component: () =>
      //     import('./../../views/branchsales/show.vue'),
      // },
      // {
      //   path: ':id/edit',
      //   meta: {
      //     label: 'Edit Branch Sales',
      //     permission: 'edit_branch_sales'
      //   },
      //   name: 'EditBranchSales',
      //   component: () =>
      //     import('./../../views/branchsales/edit.vue')
      // },
    ]
  },

  {
    path: '/sales',
    meta: {label: 'Sales'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'sales',
      meta: {permission: 'show_all_sales'},
      component: () =>
        import('./../../views/sales/index.vue'),
    },
      {
        path: 'create',
        meta: {label: 'Sales  Create', permission: 'create_sales'},
        name: 'CreateSales',
        component: () =>
          import('./../../views/sales/create.vue'),
      },
      {
        path: 'delete',
        meta: {
          label: 'Delete Sales Details',
          permission: 'delete_sales_details'
        },
        name: 'DeleteSalesDetails',
        component: () =>
          import('./../../views/sales/deleteSalesDetails.vue'),
      },
      {
        path: ':id/edit',
        meta: {label: 'Edit Sales', permission: 'show_single_sales'},
        name: 'EditSales',
        component: () =>
          import('./../../views/sales/edit.vue')
      },
      {
        path: '/upload',
        meta: {label: 'Upload', permission: 'import_sales'},
        name: 'UploadSales',
        component: () =>
          import('./../../views/sales/upload.vue'),
      }
    ]
  },

  {
    path: '/unified-code',
    meta: {label: 'Mapping Unified Code'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'unified-codes',
      meta: {
        permission: 'show_all_unified_codes'
      },
      component: () =>
        import('./../../views/mappingunifiedcode/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Create Mapping Unified Code',
          permission: 'create_unified_code'
        },
        name: 'CreateMappingUnifiedCode',
        component: () =>
          import('./../../views/mappingunifiedcode/create.vue'),
      },
      {
        path: ':id',
        meta: {
          label: 'Mapping Unified Code Details',
          permission: 'show_single_unified_code'
        },
        name: 'MappingUnifiedCode',
        component: () =>
          import('./../../views/mappingunifiedcode/show.vue'),
      },
      {
        path: ':id/edit',
        meta: {
          label: 'Edit Mapping Unified Code',
          permission: 'edit_unified_code'
        },
        name: 'EditMappingUnifiedCode',
        component: () =>
          import('./../../views/mappingunifiedcode/edit.vue')
      },
    ]
  },

  {
    path: '/contribution',
    meta: {label: 'Contribution'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'contribution',
      meta: {
        permission: 'show_all_contributions'
      },
      component: () =>
        import('./../../views/contribution/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Create Contribution',
          permission: 'create_contribution'
        },
        name: 'CreateContribution',
        component: () =>
          import('./../../views/contribution/create.vue'),
      },
    ]
  },

  {
    path: '/target-details',
    meta: {label: 'Target Details'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'target-details',
      meta: {
        permission: 'show_all_target_details'
      },
      component: () =>
        import('./../../views/targetdetails/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Create Target Details',
          permission: 'create_target_details'
        },
        name: 'CreateTargetDetails',
        component: () =>
          import('./../../views/targetdetails/create.vue'),
      },
      {
        path: 'delete',
        meta: {
          label: 'Delete Target Details',
          permission: 'delete_target_details'
        },
        name: 'DeleteTargetDetails',
        component: () =>
          import('./../../views/targetdetails/deleteTargetDetails.vue'),
      },
    ]
  },

  {
    path: '/target',
    meta: {label: 'Target'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [{
      path: '',
      name: 'target',
      meta: {
        permission: 'show_all_targets'
      },
      component: () =>
        import('./../../views/target/index.vue'),
    },
      {
        path: 'create',
        meta: {
          label: 'Create Target',
          permission: 'create_target'
        },
        name: 'CreateTarget',
        component: () =>
          import('./../../views/target/create.vue'),
      },
    ]
  },
  {
    path: '/role_sales_settings',
    meta: {label: 'Role Sales'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '',
        name: 'Role Sale Settings',
        meta: {
          permission: 'show_role_sales_settings'
        },
        component: () =>
          import('./../../views/roles-sales-setting/index.vue'),
      },
      {
        path: '/create',
        name: 'CreateRoleSaleSetting',
        meta: {
          permission: 'show_role_sales_settings'
        },
        component: () =>
          import('./../../views/roles-sales-setting/create.vue'),
      },
      {
        path: ':id/edit',
        name: 'EditRoleSaleSetting',
        meta: {
          permission: 'show_role_sales_settings'
        },
        component: () =>
          import('./../../views/roles-sales-setting/edit.vue'),
      },
    ]
  },
  {
    path: '/sales_product_weights_settings',
    meta: {label: 'Sales Product Weights'},
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '',
        name: 'Sales Product Weights',
        meta: {
          permission: 'show_sales_product_weights_settings'
        },
        component: () =>
          import('./../../views/salesProductWeightsSettings/index.vue'),
      }
    ]
  }

]

